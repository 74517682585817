import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/app-config.service';
import { ProductInfo, ProductDisplay, ProductActions, ProductActionsImpl } from '../../psp/product-info';
import { Workspace } from 'src/app/workspace/workspace';
import { UserApiService } from '../../user/user-api.service';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
@Injectable({
  providedIn: 'root'
})
export class PspService {
  private homeWorkspace: Workspace;
  private favsWorkspace: Workspace;
  private mbfPspId: string;
  pspStarRequestCode: string[] = ['TBC'];
  pspStarRequestUrl = 'http://star.web.boeing.com/star/index.jsp?sessionSiteID=148';
  user = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  constructor(private httpClient: HttpClient
    ,         private appConfigService: AppConfigService, private userApiService: UserApiService,
  ) {
    this.appConfigService.getWorkspace(this.appConfigService.getProperty('mbfHomeName')).subscribe(ws => this.homeWorkspace = ws);
    this.appConfigService.getWorkspace(this.appConfigService.getProperty('myFavsName')).subscribe(ws => this.favsWorkspace = ws);
    this.appConfigService.envConfig.subscribe(config => {
      // tslint:disable-next-line: no-string-literal
      if (config['mbfPspId']) {
        // tslint:disable-next-line: no-string-literal
        this.mbfPspId = config['mbfPspId'];
      }
      // tslint:disable-next-line: no-string-literal
      if (config['pspStarRequestUrl']) {
        // tslint:disable-next-line: no-string-literal
        this.pspStarRequestUrl = config['pspStarRequestUrl'];
      }
      // tslint:disable-next-line: no-string-literal
      if (config['pspStarRequestCode']) {
        // tslint:disable-next-line: no-string-literal
        this.pspStarRequestCode = JSON.parse(config['pspStarRequestCode']);
      }
    });
    this.userApiService.getUser().subscribe((user: User) => this.user = user);
  }

  getProductInformation(Id): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('pspEndpoint');
    return this.httpClient.get<any>(`${endpoint}/${Id}`, { observe: 'response' }).pipe(catchError(this.error));
  }

  getProductInformationApplicationId(Id): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('getProductByApplicationId');
    return this.httpClient.get<any>(`${endpoint}/${Id}`, { observe: 'response' }).pipe(catchError(this.error));
  }

  buildImgSrc(imgData: string): string {
    return (imgData && imgData.startsWith(`contentLibrary`))
      ? `${this.appConfigService.getBackendPath('')}/${imgData}`
      : imgData;

  }

  buildActions(product: ProductInfo): ProductActions {
    const actions = new ProductActionsImpl(); // NoAction, NoOpen
    if (product.id === this.mbfPspId) {

    }
    else if (product.type === 'Dashboard' && product.authorized) {
      this.buildDashboardActions(product, actions);
    }
    // smart widget & user has access
    else if (product.type === 'ServiceProvidedWidget' && product.authorized) {
      this.buildSmartWidgetActions(product, actions);
    }
    else {
      if (product.authorized) {
        this.buildBasicWidgetActions(product, actions);
      }
      else if (product.companyAuthorized && !product.authorized) {
        this.buildRequestAccessActions(product, actions);
      }
    }

    return actions;
  }

  buildFaqLink(faqid: any, productInfoId: any): string {
    let url = '';
    switch (this.appConfigService.getProperty(`mbfSupportPageTarget`)) {
      case 'portal_ng':
        url = `index.html#/Platform/apps/${productInfoId}?faqid=${faqid}`;
        break;
      case 'portal-ui':
      default:
        url = `#/Platform/psp/${productInfoId}?faqid=${faqid}`;
        break;
    }
    return url;
  }

  buildDocLink(docId: any, baseDocument: any): string {
    let url = '';
    switch (this.appConfigService.getProperty(`mbfSupportPageTarget`)) {
      case 'portal_ng':
        url = `index.html#/Platform/apps/${baseDocument}?docid=${docId}`;
        break;
      case 'portal-ui':
      default:
        url = `#/Platform/psp/${baseDocument}?docid=${docId}`;
        break;
    }
    return url;
  }

  /**
   * Determine if already added to MBF Home, build action section appropriately
   * @param product the product we're building actions for
   * @param actions the ProductActions object we're populating
   */
  private buildDashboardActions(product: ProductInfo, actions: ProductActions) {
    const onHome = this.inParentContainer(product, this.homeWorkspace.id);

    if (onHome) {
      actions.actionArea.action = ProductDisplay.AddedToHome;
      actions.openArea.action = ProductDisplay.AvailableOnHome;
      actions.openArea.url = `${this.appConfigService.getPortalUIPath()}/index.html#/Platform/workspace/home`;

    }
    else {
      actions.actionArea.action = ProductDisplay.AddToHome;
    }

  }

  /**
   * Determine if already added to Favorites, build action section appropriately
   * @param product the product we're building actions for
   * @param actions the ProductActions object we're populating
   */
  private buildBasicWidgetActions(product: ProductInfo, actions: ProductActions) {
    const onFavs = this.inParentContainer(product, this.favsWorkspace.id);

    actions.actionArea.action = onFavs
      ? ProductDisplay.AddedToFavs
      : ProductDisplay.AddToFavs;

    if (product.url) {
      actions.openArea.action = ProductDisplay.Open;
      actions.openArea.url = product.url;
    }
  }
  private buildSmartWidgetActions(product: ProductInfo, actions: ProductActions) {
    const onFavs = this.inParentContainer(product, this.favsWorkspace.id);

    actions.actionArea.action = onFavs
      ? ProductDisplay.AddedToFavs
      : ProductDisplay.AddToFavs;

    if (onFavs) {
      actions.openArea.action = ProductDisplay.AvailableOnFav;
      actions.openArea.url = `#/Platform/workspace/home`;
      actions.openArea.url = `${this.appConfigService.getPortalUIPath()}/index.html#/Platform/workspace/home`;
    }
  }
  /**
   * Determine if company has access & user does not have access, build action section appropriately
   * @param product the product we're building actions for
   * @param actions the ProductActions object we're populating
   */
  private buildRequestAccessActions(product: ProductInfo, actions: ProductActions) {
    if (this.pspStarRequestCode.find(x => x.toLowerCase() === this.user.airlineCode.toLowerCase())) {
      actions.actionArea.action = ProductDisplay.StarRequestAccess;
      actions.actionArea.url = this.pspStarRequestUrl;
    }
    else {
      actions.actionArea.action = ProductDisplay.RequestAccess;
    }
  }

  /*
   * ProductInfo may contain addedTo array, check if parent container is in it
   * @param product
   * @param parentWidgetId
   */
  private inParentContainer(product: ProductInfo, parentContainerId: string): boolean {
    return product.addedTo &&
      product.addedTo.filter(parentWidget => parentWidget && parentWidget.id === parentContainerId).length > 0;
  }
  public requestAccess(widgetId: string): Observable<any> {
    const url = `${this.appConfigService.getBackendPath()}/appStore/app/${widgetId}/request`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(url, httpOptions).pipe(catchError(this.error));
  }
  addWidget(widgetId: string, productDisplay: ProductDisplay): Observable<any> {
    const parentContainerId = (productDisplay === ProductDisplay.AddToHome)
      ? this.homeWorkspace.id
      : this.favsWorkspace.id;

    const url = `${this.appConfigService.getBackendPath()}/widgets/${parentContainerId}/layouts?widgetIds=${widgetId}`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.post(url, httpOptions).pipe(catchError(this.error));
  }
  removeWidgetLayoutByIdFromApp(widgetId: string, productDisplay: ProductDisplay): Observable<any> {
    const parentContainerId = (productDisplay === ProductDisplay.AddedToHome)
      ? this.homeWorkspace.id
      : this.favsWorkspace.id;
    const url = `${this.appConfigService.getBackendPath()}/widgets/${widgetId}/layouts/${parentContainerId}/removefromapp`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.post(url, httpOptions).pipe(catchError(this.error));
  }

  getFAQData(widgetId: string): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('getFaqEndPoint') + '/' + widgetId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(endpoint, httpOptions).pipe(catchError(this.error));
  }

  getDocumentsByWidgetId(widgetId: string): Observable<any> {
    const backendURL = this.appConfigService.getBackendPath('getDocumentsEndPoint') + '/' + widgetId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL, httpOptions).pipe(catchError(this.error));
  }

  getFilterDocumentsByWidgetId(widgetId: string): Observable<any> {
    const backendURL = this.appConfigService.getBackendPath('getFilterDocumentsByWidgetId') + '/' + widgetId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL, httpOptions).pipe(catchError(this.error));
  }
  downloadFile(documentId: string) {
    const requesturl = this.appConfigService.getBackendPath('downloadDocumentEndPoint') + '/' + documentId;
    window.open(requesturl, 'Download');
  }
  getDocumentDetails(documentId: string): Observable<any> {
    const backendURL = this.appConfigService.getBackendPath('getDocumentDetailsEndPoint') + '/' + documentId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' })
                          , responseType: 'text' as 'json' };
    return this.httpClient.get(backendURL, httpOptions).pipe(catchError(this.error));
  }
  getDocumentDetailsById(documentId): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('getDocumentDetailsById') + '/' + documentId;
    return this.httpClient.get<any>(endpoint, { observe: 'response' });
  }
  changeViewCount(documentId: string): Observable<any> {
    const backendURL = this.appConfigService.getBackendPath('changeViewCount') + '/' + documentId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL, httpOptions).pipe(catchError(this.error));
  }
  getPublishedReleaseNoteByWidgetId(widgetId: string): Observable<any> {
    const backendURL = this.appConfigService.getBackendPath('getPublishedReleaseNotesEndPoint') + '/' + widgetId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL, httpOptions).pipe(catchError(this.error));
  }
  downloadReleaseNote(documentId: string) {
    const requesturl = this.appConfigService.getBackendPath('downloadReleaseNote') + '/' + documentId;
    window.open(requesturl, 'Download');
  }
  getPublishedRelatedSoftwareByWidgetId(widgetId: string): Observable<any> {
    const backendURL = this.appConfigService.getBackendPath('getPublishedRelatedSoftwareEndPoint') + '/' + widgetId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL, httpOptions).pipe(catchError(this.error));
  }
  viewSoftwareCountById(softwareId: string): Observable<any> {
    const backendURL = this.appConfigService.getBackendPath('viewSoftwareCountById') + '/' + softwareId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL, httpOptions).pipe(catchError(this.error));
  }
  downloadSoftware(softwareId: string) {
    const requesturl = this.appConfigService.getBackendPath('downloadSoftware') + '/' + softwareId;
    window.open(requesturl, 'Download');
  }

  // Handle Errors
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
  likeFaqCount(faqId: string){
    const backendURL = this.appConfigService.getBackendPath('likeFaq');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL.replace('{quesAnsId}', faqId), httpOptions).pipe(catchError(this.error));
  }
  dislikeFaqCount(faqId: string){
    const backendURL = this.appConfigService.getBackendPath('dislikeFaq');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL.replace('{quesAnsId}', faqId), httpOptions).pipe(catchError(this.error));
  }
  getLegalInfoByWidgetId(widgetId: string){
    const backendURL = this.appConfigService.getBackendPath('getAllLegalInfoByWidgetId');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL.replace('{widgetId}', widgetId), httpOptions).pipe(catchError(this.error));
  }

  getLegalInfoByLegalId(id: string){
    const backendURL = this.appConfigService.getBackendPath('getLegalInfoByLegalId');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(backendURL.replace('{legalId}', id), httpOptions).pipe(catchError(this.error));
  }

  downloadLegalDocument(documentId: string) {
    const requesturl = this.appConfigService.getBackendPath('downloadLegalDocument');
    window.open(requesturl.replace('{documentId}', documentId), 'Download');
  }
}
