<div class="hidden sm:hidden md:block lg:block">
    <div class="footer">
        <div class="portal-footer-links">
            <div [ngClass]="{'footer-title-pb-12': category.title !== 'MBF Support'}" *ngFor="let category of portalLinks; let i=index;">
                <div class="text-white text-left footer-title font-bold  subpixel-antialiased footer-title-pb-8">
                    {{category.title}}</div>
                <div *ngFor="let link of category.links" class="footer-link-pb-5">
                    <a *ngIf="nonTBCUser" class="footer-links text-white mat-footer-highlight cursor-pointer"
                        (click)="urlClicked(link)"
                        [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                        href="javascript:void(0);">{{link.title}}</a>
                    <a *ngIf="!nonTBCUser" (click)="urlClicked(link)"
                        class="footer-links text-white mat-footer-highlight cursor-pointer"
                        [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                </div>
            </div>
        </div>
        <div class="shop-discover-footer-links">
            <div [ngClass]="{'footer-title-pb-12': category.title !== 'Discover'}" *ngFor="let category of shopAndDiscoverLinks">
                <div class="text-white text-left footer-title font-bold  subpixel-antialiased footer-title-pb-8">
                    {{category.title}}</div>
                <div *ngFor="let link of category.links" class="footer-link-pb-5">
                  <a *ngIf="nonTBCUser" class="footer-links text-white mat-footer-highlight cursor-pointer"
                      (click)="getURL(link.url, link.urlTarget, link.type, link.title)"
                      [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>

                  <a *ngIf="!nonTBCUser" (click)="getURL(link.url, link.type, link.title,link.urlTarget)"
                      class="footer-links text-white mat-footer-highlight cursor-pointer"
                      [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                </div>
            </div>
        </div>
        <div class="boeing-footer-links">
            <div class="footer-title-pb-12" *ngFor="let category of boeingLinks">
                <div class="text-white text-left footer-title font-bold  subpixel-antialiased footer-title-pb-8">
                    {{category.title}}</div>
                <div *ngFor="let link of category.links" class="footer-link-pb-5">
                  <a *ngIf="nonTBCUser" class="footer-links text-white mat-footer-highlight cursor-pointer"
                      (click)="getURL(link.url, link.urlTarget, link.type, link.title)"
                      [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                      href="javascript:void(0);">{{link.title}}</a>
                  <a *ngIf="!nonTBCUser" (click)="getURL(link.url, link.type, link.title,link.urlTarget)"
                      class="footer-links text-white mat-footer-highlight cursor-pointer"
                      [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                </div>
            </div>
        </div>
        <div class="media-footer-links">
            <div class="footer-title-pb-12" *ngFor="let category of mediaLinks">
                <div class="text-white text-left footer-title font-bold  subpixel-antialiased footer-title-pb-8">
                    {{category.title}}</div>
                <div *ngFor="let link of category.links" class="footer-link-pb-5">
                    <span *ngIf="link.url === ''; then noLink else OtherLinks"></span>
                    <ng-template #noLink>
                        <div class="footer-link-pb-5">
                            <span class="footer-links font-meso text-gray-400">{{link.title}}</span>
                        </div>
                    </ng-template>
                    <ng-template #OtherLinks>
                      <a *ngIf="nonTBCUser" class="footer-links text-white mat-footer-highlight cursor-pointer"
                          (click)="getURL(link.url, link.urlTarget, link.type, link.title)"
                          [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                          href="javascript:void(0);">{{link.title}}</a>
                      <a *ngIf="!nonTBCUser" (click)="getURL(link.url, link.type, link.title,link.urlTarget)"
                          class="footer-links text-white mat-footer-highlight cursor-pointer"
                          [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                    </ng-template>
                </div>
            </div>
            <div class="text-white text-left footer-title font-bold  subpixel-antialiased footer-title-pb-8 social-media-title">
                {{socialMediaTitle}}</div>
            <div class="social-media-links">
                <div *ngFor="let category of socialMedia" class="footer-link-pb-5">
                  <img class="social-media-icons cursor-pointer" src="{{category.image}}"
                      (click)="getURL(category.url, category.type, category.title, category.urlTarget)"
                      [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category]}">
                </div>
            </div>
        </div>
    </div>
</div>


<div class="block sm:block md:hidden lg:hidden ft-w-18">
    <div class="footer">
        <div class="portal-footer-links" *ngFor="let category of portalLinks; let isFirst = first">
            <mat-accordion class="footer-accordion">
                <mat-expansion-panel class="footer-accordion-panel" [expanded]="isFirst">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="footer-accordion-panel-title">{{category.title}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let link of category.links" class="footer-link-pb-5">
                      <a *ngIf="nonTBCUser" class="footer-links text-white mat-footer-highlight cursor-pointer"
                          (click)="urlClicked(link)"
                          [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                          href="javascript:void(0);">{{link.title}}</a>
                      <a *ngIf="!nonTBCUser" (click)="urlClicked(link)"
                          class="footer-links text-white mat-footer-highlight cursor-pointer"
                          [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="shop-discover-footer-links" *ngFor="let category of shopAndDiscoverLinks">
            <mat-accordion class="footer-accordion">
                <mat-expansion-panel class="footer-accordion-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="footer-accordion-panel-title">{{category.title}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let link of category.links" class="footer-link-pb-5">
                      <a *ngIf="nonTBCUser" class="footer-links text-white mat-footer-highlight cursor-pointer"
                          (click)="getURL(link.url, link.urlTarget, link.type, link.title)"
                          [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                          href="javascript:void(0);">{{link.title}}</a>
                      <a *ngIf="!nonTBCUser" (click)="getURL(link.url, link.type, link.title,link.urlTarget)"
                          class="footer-links text-white mat-footer-highlight cursor-pointer"
                          [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="boeing-footer-links" *ngFor="let category of boeingLinks">
            <mat-accordion class="footer-accordion">
                <mat-expansion-panel class="footer-accordion-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="footer-accordion-panel-title">{{category.title}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let link of category.links" class="footer-link-pb-5">
                      <a *ngIf="nonTBCUser" class="footer-links text-white mat-footer-highlight cursor-pointer"
                          (click)="getURL(link.url, link.urlTarget, link.type, link.title)"
                          [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                          href="javascript:void(0);">{{link.title}}</a>
                      <a *ngIf="!nonTBCUser" (click)="getURL(link.url, link.type, link.title,link.urlTarget)"
                          class="footer-links text-white mat-footer-highlight cursor-pointer"
                          [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="media-footer-links" *ngFor="let category of mediaLinks">
            <mat-accordion class="footer-accordion">
                <mat-expansion-panel class="footer-accordion-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="footer-accordion-panel-title">{{category.title}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let link of category.links" class="footer-link-pb-5">
                        <span *ngIf="link.url === ''; then noLink else OtherLinks"></span>
                        <ng-template #noLink>
                          <div class="footer-link-pb-5">
                            <span class="footer-links font-meso text-gray-400">{{link.title}}</span>
                          </div>
                        </ng-template>
                        <ng-template #OtherLinks>
                          <a *ngIf="nonTBCUser" class="footer-links text-white mat-footer-highlight cursor-pointer"
                              (click)="getURL(link.url, link.urlTarget, link.type, link.title)"
                              [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                              href="javascript:void(0);">{{link.title}}</a>
                          <a *ngIf="!nonTBCUser" (click)="getURL(link.url, link.type, link.title,link.urlTarget)"
                              class="footer-links text-white mat-footer-highlight cursor-pointer"
                              [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                        </ng-template>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div
            class="text-white text-left footer-title font-bold  subpixel-antialiased pt-4 pb-8 social-media-footer-title">
            {{socialMediaTitle}}</div>
        <div class="social-media-links">
            <div *ngFor="let category of socialMedia">
                <div class="footer-link-pb-5">
                  <img class="social-media-icons cursor-pointer" src="{{category.image}}"
                      (click)="getURL(category.url, category.type, category.title, category.urlTarget)"
                      [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category]}">
                </div>
            </div>
        </div>

    </div>
</div>
<app-nxg-skinny-footer></app-nxg-skinny-footer>
